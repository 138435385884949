.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.centered {
  text-align: center;
}

.logo {
  margin-top: -50%;
  width: 50%; /* Adjust the width as per your logo size */
  height: auto;
}

.paragraph {
  /* margin-top: 20px; */
  margin-top: -10%;
  font-size: 18px;
  /* Additional styles for your paragraph */
}
